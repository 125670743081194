<template>
	<div>
		<div class="father_box">
			<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/Doorboard.png" class="Doorboard" />
			<div class="introduce1">
				<div class="name">河南君然电子科技有限公司</div>
				<div class="value">君然网络科技有限公司是一家为企业转型"互联网+"提供解决方案的移动战略服务商，主营业务包括:互联网项目咨询策划、APP应
					用开发、网站建设、微信应用开发H5应用开发企业互联网战略发展咨询等。君然已经为超过3000+家大型事业单位、个人创客提供了一站式的互企业集团、中小企业、联网解决方案在农业、养老、教育、医疗、金融、旅游等数十个行业领域积累了丰富的"互联网+"项目经验。
				</div>
			</div>
		</div>
		<div class="photo">
			<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos4.jpg" />
			<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos1.png" />
			<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos2.png" />
			<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/CompanyPhotos3.png" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {}
	}
</script>

<style scoped>
	.father_box {
		width: 43rem;
		margin: 3rem auto 0;
		position: relative;
	}

	.Doorboard {
		width: 21rem;
		/* height: 15.6rem; */
		position: relative;
		z-index: 1;
		border-radius: 0.6rem;
	}

	.introduce1 {
		width: 28.8rem;
		/* height: 16.8rem; */
		background: linear-gradient(153deg, rgba(253, 253, 253, 0.1) 0%, #FBFBFB 100%);
		position: relative;
		z-index: 3;
		padding: 2.31rem 1.56rem;
		float: right;
		margin-top: -10rem;
		border-radius: 0.9rem;
		backdrop-filter: blur(10px);
	}

	.introduce1>.name {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333333;
		font-size: 1.32rem;
		font-weight: 500;
	}

	.value {
		color: #333333;
		font-size: 0.9rem;
		margin-top: 1.5rem;
		font-weight: 500;
		text-align: left;
	}

	.photo {
		width: 43rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding-top: 1.8rem;
		padding-bottom: 3rem;
	}

	.photo>img {
		width: 10rem;
		height: 6.2rem;
		border-radius: 0.42rem;
	}
</style>